import attach from "./attach";

let attached = false;

function callAction(action) {
  if (typeof action === "function") {
    return action();
  }
  return null;
}

export default async (action) => {
  return new Promise(async (resolve, reject) => {
    if (attached) {
      resolve(callAction(action));
      return;
    }
    await attach(() => {
      attached = true;
      resolve(callAction(action));
    }, () => {
      reject({ message: 'Failed to load Stripe' });
    })
  });
}
